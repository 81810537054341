<template>
  <div class=container>
  <div id="everything">
    <div id="header">
    <div class="left"><h1><router-link to="/repository/PubFig">PubFig: Public Figures Face Database</router-link></h1></div><br>
    <div class="right">
    <h2><router-link to="/repository/PubFig/explore" class="unselected">Explore</router-link></h2>
    <h2><router-link to="/repository/PubFig/download" class="unselected">Download</router-link></h2>
    <h2><router-link to="/repository/PubFig/results" class="unselected">Results</router-link></h2>
    </div>
</div>
<br>
<div id="maincontent">
    <img class="teaser" width="100%" src="https://cave.cs.columbia.edu/old/databases/pubfig/pubfig.png">
    <h2>Introduction</h2>
    <p>The PubFig database is a large, real-world face dataset consisting of <b>58,797</b> images of <b>200</b> people collected from the internet. Unlike most other existing face datasets, these images are taken in completely uncontrolled situations with non-cooperative subjects. Thus, there is large variation in pose, lighting, expression, scene, camera, imaging conditions and parameters, etc. The PubFig dataset is similar in spirit to the <a href="http://vis-www.cs.umass.edu/lfw/">Labeled Faces in the Wild (LFW) dataset</a> created at UMass-Amherst, although there are some significant differences in the two:
    </p><ul>
        <li>LFW contains <b>13,233 images</b> of <b>5,749 people</b>, and is thus much broader than PubFig. However, it's also smaller and much shallower (many fewer images per person on average).</li>
        <li>LFW is derived from the Names and Faces in the News work of T. Berg, et al. These images were originally collected using news sources online. For many people, there are often several images taken at the same event, with the person wearing similar clothing and in the same environment. <a href="https://cave.cs.columbia.edu/projects/categories/project?cid=Visual+Recognition&pid=Attribute+and+Simile+Classifiers+for+Face+Verification">Our paper at ICCV 2009</a> showed that this can often be exploited by algorithms to give unrealistics boosts in performance.</li>
        <li>Of course, the PubFig dataset no doubt has biases of its own, and we welcome any attempts to categorize these.</li>
    </ul>
    <p></p>
    <p>We have created a face verification benchmark on this dataset that test the abilities of algorithms to classify a pair of images as being of the same person or not. Importantly, these two people should have <b>never</b> been seen by the algorithm during training. In the future, we hope to create recognition benchmarks as well.</p>

    <h2>Citation</h2>
    <p><b>The database is made available only for non-commercial use. If you use this dataset, please cite the following paper:</b>
    </p><blockquote id="kumar_iccv_09">
    <div class="txtref">
        <b>"Attribute and Simile Classifiers for Face Verification,"</b><br>
        Neeraj Kumar, Alexander C. Berg, Peter N. Belhumeur, and Shree K. Nayar,<br>
        <i>International Conference on Computer Vision (ICCV), 2009.</i><br>
    </div>
    [<a href="http://cave.cs.columbia.edu/old/publications/pdfs/Kumar_ICCV09.pdf">pdf</a>]
    [<a href="/projects/categories/project?cid=Visual+Recognition&pid=Attribute+and+Simile+Classifiers+for+Face+Verification" target="_blank">webpage</a>]

</blockquote>

    <h2>News</h2>
    <ul>
        <li>December 23, 2010: Updated PubFig to v1.2. The changes are as follows:<ul>
            <li>We added md5 checksums for all images in the datafiles on the <a href="/repository/PubFig/download">download</a> page.</li>
        </ul></li>
        <li>September 10, 2010: Updated PubFig to v1.1. The major changes are as follows:<ul>
            <li>We recomputed attribute values using updated classifiers, expanding to 73 attributes.</li>
            <li>Attribute values now exist for the development set as well as the evaluation set (previously only the evaluation set had attribute values).</li>
            <li>We updated the face rectangles for faces to be much tighter around the face, as opposed to the rather loose boundaries given before.</li>
            <li>We removed 679 bad images, including non-jpegs, images with non-standard colorspaces, corrupted images, and images with very poor alignment.</li>
            <li>We generated a new cross-validation set, taking into account these deleted images. We ran our algorithm with our new attribute classifiers on this set, obtaining a new curve.</li>
            <li>We removed the verification subsets by pose, lighting, and expression, as they were not being used. Instead, we created a <a href="download/#pubfig_labels">single datafile</a> which contains the manual labels for these parameters.</li>
            <li>Some of the datafile formats have changed slightly, to be more consistent with the others.</li>
            <li>We added the python script used to generate the output ROC curves</li>
            <li>We updated this website to be cleaner and easier to read</li>
        </ul></li>
        <li>December 21, 2009: Added face locations to dataset</li>
        <li>December 2, 2009: Created website and publicly released v1.0 of dataset</li>
    </ul>

    <h2>Related Projects</h2>
    <ul>
        <li><a href="/projects/categories/project?cid=Visual+Recognition&pid=Attribute+and+Simile+Classifiers+for+Face+Verification">Attribute and Simile Classifiers for Face Verification</a> (Columbia)</li>
        <li><a href="/projects/categories/project?cid=Visual+Recognition&pid=FaceTracer+A+Search+Engine+for+Large+Face+Collections">FaceTracer: A Search Engine for Large Collections of Images with Faces</a> (Columbia)</li>
        <li><a href="http://vis-www.cs.umass.edu/lfw/">Labeled Faces in the Wild</a> (UMass-Amherst)</li>
    </ul>
</div>

<div>
    (c) 2010 Columbia University | Contact <a href="mailto:neeraj@cs.columbia.edu">Neeraj Kumar</a> for any questions/problems/bug reports/etc. | Current PubFig version: <b>1.2</b>
</div>
<br>
  </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
#everything {
    font-family: Arial, sans-serif;
    padding: 10px;
    line-height: 1.3em;
    margin: 0 auto;
    width: 1000px;
    margin-top: 10px;
}

.teaser {
    display: block;
    margin: 0 auto;
}

a {
    color: blue;
    text-decoration: none;
}

#header {
    position: relative;
    display: block;
    height: 2.2em;
    /* border-bottom: 1px solid lightgray; */
    margin-bottom: 10px;
}

#header h2 {
    margin-top: 5px;
    display: inline-block;
    margin-left: 15px;
}

#header a {
    color: black;
}

#header a:hover {
    color: blue;
}

a:hover {
    text-decoration: underline;
}

#header .left {
    position: absolute;
    left: 0;
}

#header .left h2 {
    margin-left: 0;
}

#header .right {
    position: absolute;
    right: 0;
}

#header .selected {
    color: #ff872b;
}

h1 {
    font-size: 2.0em;
    margin-bottom: 10px;
}

h1.sectiontitle {
    text-align: center;
    margin: 20px;
}

h2 {
    font-size: 1.2em;
    margin: 20px 0 10px 0;
}

p {
    margin-bottom: 1em;
}

li {
    list-style: square outside;
    margin-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

ol li {
    list-style-type: decimal;
    margin-left: 1.5em;
}

li li {
    list-style: circle outside;
}



div#content {
    clear: both;
    width: 100%;
    background: #f1ebda;
    padding-top: 10px;
    padding-bottom: 3em;
}

table caption {
    font-size: 2em;
}

h3 {
    margin-top: 5px;
    text-align: center;
}


div.resultpane {
    margin: 0 auto;
    margin-bottom: 30px;
}

div.rocdiv {
    margin-top: 2em;
    text-align: center;
}
div.tablediv {
    position: relative;
    text-align: center;
    margin-bottom: 1em;
}
table.resultstable {
    margin: 0 auto;
    border: 1px solid black;
}
table.resultstable th {
    border-bottom: 1px dotted black;
    padding: 5px;
    text-align: center;
}

table.resultstable td {
    padding: 5px;
    text-align: center;
}

.first{
    padding-right: 5px;
    border-right: 1px dotted black;
}

.exploretable {
    margin: 0 auto;
    text-align: center;
}


.exploreperson {
    display: inline-block;
    width: 186px;
    height: 130px;
    margin-bottom: 1em;
    margin-left: 5px;
    margin-right: 5px;
}

.exploreperson img {
    width: 170px;
    height: 85px;
    padding: 2px;
    border: 1px solid lightgray;
}

.exploreperson a:hover img {
    border-color: blue;
}

blockquote {
    margin-left: 2em;
    margin-top: 0.5em;
}

.fakelink {
    cursor: pointer;
}

.bibref {
    margin-top: 10px;
    margin-left: 10px;
    display:none;
    font-family: monospace;
}


.code {
    font-family: monospace;
    font-size: 1.2em;
}

pre {
    text-align: left;
    padding: 5px;
    background: lightgray;
    margin: 5px 0;
}

</style>